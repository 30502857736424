<template>
  <div class="seo-setting-index relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <div class="relative mt-5">
        <div class="page-list">
          <a-card title="SEO Management">
            <a-collapse
              :activeKey="activeKey"
              expandIconPosition="right"
              @change="onChangeCollapse"
            >
              <a-collapse-panel
                key="1"
                header="Homepage"
                :showArrow="false"
                @click="
                  () => {
                    $router.push({
                      name: `EditSEO`,
                      params: {
                        page: `home`,
                      },
                    });
                  }
                "
              >
              </a-collapse-panel>
              <a-collapse-panel
                key="2"
                header="About Page"
                :showArrow="false"
                @click="
                  () => {
                    $router.push({
                      name: `EditSEO`,
                      params: {
                        page: `about`,
                      },
                    });
                  }
                "
              >
              </a-collapse-panel>
              <a-collapse-panel
                key="3"
                header="Contact Page"
                :showArrow="false"
                @click="
                  () => {
                    $router.push({
                      name: `EditSEO`,
                      params: {
                        page: `contact`,
                      },
                    });
                  }
                "
              >
              </a-collapse-panel>
              <a-collapse-panel
                key="4"
                header="Services"
                @click="
                  () => {
                    activeKey.includes('4') ? getServices() : '';
                  }
                "
              >
                <table
                  class="
                    border-collapse
                    table-auto
                    w-full
                    whitespace-no-wrap
                    bg-white
                    table-striped
                    relative
                  "
                >
                  <thead>
                    <tr class="text-left">
                      <th
                        class="
                          bg-gray-200
                          sticky
                          top-0
                          border-b border-gray-200
                          px-6
                          py-2
                          text-gray-600
                          font-bold
                          tracking-wider
                          uppercase
                          text-xs
                        "
                      >
                        Name
                      </th>
                      <th
                        class="
                          bg-gray-200
                          sticky
                          top-0
                          border-b border-gray-200
                          px-6
                          py-2
                          text-gray-600
                          font-bold
                          tracking-wider
                          uppercase
                          text-xs
                        "
                      >
                        Category Name
                      </th>
                      <th
                        class="
                          bg-gray-200
                          sticky
                          top-0
                          border-b border-gray-200
                          px-6
                          py-2
                          text-gray-600
                          font-bold
                          tracking-wider
                          uppercase
                          text-xs text-center
                        "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="!isLoadingService">
                    <tr v-for="item in serviceList" :key="item">
                      <td
                        class="
                          border-dashed border-t border-gray-200
                          text-center
                        "
                      >
                        <span
                          class="
                            text-gray-700
                            px-6
                            py-3
                            flex
                            items-center
                            text-base
                          "
                          >{{ item.serviceName }}</span
                        >
                      </td>
                      <td
                        class="
                          border-dashed border-t border-gray-200
                          text-center
                        "
                      >
                        <span
                          class="
                            text-gray-700
                            px-6
                            py-3
                            flex
                            items-center
                            text-base
                          "
                          >{{ item.serviceCategoryName }}</span
                        >
                      </td>
                      <td
                        class="
                          border-dashed border-t border-gray-200
                          text-center
                        "
                      >
                        <a-button
                          @click="
                            () => {
                              $router.push({
                                name: `EditSEO`,
                                params: {
                                  page: `service_detail`,
                                },
                                query: {
                                  model_id: item.id,
                                },
                              });
                            }
                          "
                          >Manage</a-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-if="isLoadingService" class="loader"></div>
              </a-collapse-panel>
              <a-collapse-panel
                key="5"
                header="Products"
                @click="
                  () => {
                    activeKey.includes('5') ? getProducts() : '';
                  }
                "
              >
                <table
                  class="
                    border-collapse
                    table-auto
                    w-full
                    whitespace-no-wrap
                    bg-white
                    table-striped
                    relative
                  "
                >
                  <thead>
                    <tr class="text-left">
                      <th
                        class="
                          bg-gray-200
                          sticky
                          top-0
                          border-b border-gray-200
                          px-6
                          py-2
                          text-gray-600
                          font-bold
                          tracking-wider
                          uppercase
                          text-xs
                        "
                      >
                        Name
                      </th>
                      <th
                        class="
                          bg-gray-200
                          sticky
                          top-0
                          border-b border-gray-200
                          px-6
                          py-2
                          text-gray-600
                          font-bold
                          tracking-wider
                          uppercase
                          text-xs
                        "
                      >
                        Category Name
                      </th>
                      <th
                        class="
                          bg-gray-200
                          sticky
                          top-0
                          border-b border-gray-200
                          px-6
                          py-2
                          text-gray-600
                          font-bold
                          tracking-wider
                          uppercase
                          text-xs text-center
                        "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="item in productList" :key="item">
                      <td
                        class="
                          border-dashed border-t border-gray-200
                          text-center
                        "
                      >
                        <span
                          class="
                            text-gray-700
                            px-6
                            py-3
                            flex
                            items-center
                            text-base
                          "
                          >{{ item.productName }}</span
                        >
                      </td>
                      <td
                        class="
                          border-dashed border-t border-gray-200
                          text-center
                        "
                      >
                        <span
                          class="
                            text-gray-700
                            px-6
                            py-3
                            flex
                            items-center
                            text-base
                          "
                          >{{ item.productCategoryName }}</span
                        >
                      </td>
                      <td
                        class="
                          border-dashed border-t border-gray-200
                          text-center
                        "
                      >
                        <a-button
                          @click="
                            () => {
                              $router.push({
                                name: `EditSEO`,
                                params: {
                                  page: `product_detail`,
                                },
                                query: {
                                  model_id: item.id,
                                },
                              });
                            }
                          "
                          >Manage</a-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </a-collapse-panel>
              <a-collapse-panel
                key="6"
                header="Doctors"
                @click="
                  () => {
                    activeKey.includes('6') ? getDoctorList() : '';
                  }
                "
              >
                <table
                  class="
                    border-collapse
                    table-auto
                    w-full
                    whitespace-no-wrap
                    bg-white
                    table-striped
                    relative
                  "
                >
                  <thead>
                    <tr class="text-left">
                      <th
                        class="
                          bg-gray-200
                          sticky
                          top-0
                          border-b border-gray-200
                          px-6
                          py-2
                          text-gray-600
                          font-bold
                          tracking-wider
                          uppercase
                          text-xs
                        "
                      >
                        Name
                      </th>
                      <th
                        class="
                          bg-gray-200
                          sticky
                          top-0
                          border-b border-gray-200
                          px-6
                          py-2
                          text-gray-600
                          font-bold
                          tracking-wider
                          uppercase
                          text-xs text-center
                        "
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="item in doctorList" :key="item">
                      <td
                        class="
                          border-dashed border-t border-gray-200
                          text-center
                        "
                      >
                        <span
                          class="
                            text-gray-700
                            px-6
                            py-3
                            flex
                            items-center
                            text-base
                          "
                          >{{ item.doctorName }}</span
                        >
                      </td>
                      <td
                        class="
                          border-dashed border-t border-gray-200
                          text-center
                        "
                      >
                        <a-button
                          @click="
                            () => {
                              $router.push({
                                name: `EditSEO`,
                                params: {
                                  page: `doctor_info`,
                                },
                                query: {
                                  model_id: item.id,
                                },
                              });
                            }
                          "
                          >Manage</a-button
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </a-collapse-panel>
            </a-collapse>
          </a-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";

import moment from "moment";

export default {
  name: "SeoSetting",
  components: { SideNavBar },
  data() {
    return {
      activeKey: [],
      serviceList: [],
      isLoadingService: false,
      productList: [],
      isLoadingProduct: false,
      doctorList: [],
      isLoadingDoctor: false,
    };
  },
  mounted() {
    this.moment = moment;
  },
  methods: {
    getServices() {
      this.isLoadingService = true;

      this.axios({
        url: "/service/getServices",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.serviceList = response.data;
          this.isLoadingService = false;
        })
        .catch((error) => {
          this.isLoadingService = false;
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    getProducts() {
      this.isLoadingProduct = true;

      this.axios({
        url: "/product/getProducts",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.productList = response.data;
          this.isLoadingProduct = false;
        })
        .catch((error) => {
          this.isLoadingProduct = false;
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    getDoctorList() {
      this.isLoadingDoctor = true;

      this.axios({
        url: "/doctor/getDoctors",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.doctorList = response.data;
          this.isLoadingDoctor = false;
        })
        .catch((error) => {
          this.isLoadingDoctor = false;
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    onChangeCollapse(keys) {
      this.activeKey = keys.filter((e) => e != "1" && e != "2" && e != "3");
    },
    showToastDialog(response) {
      this.$swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        icon: "success",
        title: response.data["message"],
      });
    },
    navigateToEditBranch(branchID) {
      this.$router.push({ name: "EditBranch", params: { branchID: branchID } });
    },
    navigateToAddBranch() {
      this.$router.push({ name: "AddBranch" });
    },
  },
};
</script>

<style>
.seo-setting-index .ant-collapse-header {
  font-weight: 600;
  font-size: 18px;
}

.seo-setting-index .ant-collapse-item svg {
  font-size: 16px;
}
</style>